import * as React from 'react';

import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Section } from '../../../layout/Section';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import styled from 'styled-components';

const TransparencyAct: React.FC = () => {
  const { fadeIn } = useFadeTransition({
    immidiate: true
  });

  return (
    <Container style={{ paddingTop: '100px', minHeight: '600px' }}>
      <Section style={fadeIn}>
        <TransparencyActWrapper>
          <Heading tag="h1" center={true}>
            Åpenhetsloven
          </Heading>
          <a
            className="readMore"
            aria-label="Last ned - Redegjørelse åpenhetsloven 2024.pdf"
            href="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Redegjørelse åpenhetsloven 2024.pdf"
            download={true}
          >
            Last ned - Redegjørelse åpenhetsloven 2024.pdf
          </a>
        </TransparencyActWrapper>
      </Section>
    </Container>
  );
};

const TransparencyActWrapper = styled.div`
  .primary {
    color: ${({ theme }) => theme.colors.primary};
  }

  .bold {
    font-weight: 400;
  }

  .readMore {
    display: block;
    text-align: center;
  }
`;
export default TransparencyAct;
